<template>
  <v-card>
    <v-form @submit.prevent="handleAccept()" ref="form" v-model="valid">
      <v-card-text>
        <div v-show="!isLoading">
          <VTextField
              :rules="[(v) => !!v || 'E-Mail is verplicht', () => serverError('email')]"
              label="E-Mail"
              v-model="email"
              class="required"
          />
          <VTextField
              :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
              :rules="[(v) => !!v || 'Wachtwoord is verplicht', () => serverError('password')]"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              label="Wachtwoord"
              v-model="password"
              class="required"
          />
          <VTextField
              :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
              :rules="[(v) => !!v || 'Wachtwoord is verplicht', () => serverError('passwordConfirmation')]"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              label="Herhaal wachtwoord"
              v-model="passwordConfirmation"
              class="required"
          />
        </div>
        <div class="text-center" v-show="isLoading">
          <VProgressCircular
              :size="70"
              :width="7"
              color="accent"
              indeterminate
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn type="submit" color="accent">Aanmaken</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { invitationAccept } from '@/api/endpoints/authorisation/register.js';
import BaseForm from '../BaseForm.vue';

export default {
  name: 'InvitationAcceptCard',
  extends: BaseForm,
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      passwordConfirmation: '',
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
    }),
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push({
        name: 'home',
      });
    }
  },
  methods: {
    handleAccept() {
      this.validate();

      if (!this.valid) return;

      this.isLoading = true;

      invitationAccept(this.email, this.password, this.passwordConfirmation, this.$route.params.token)
          .then(() => this.$router.push({
                name: 'login',
              }),
          )
          .catch(() => {
            this.validate();
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
};
</script>
