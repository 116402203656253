import { post } from '@/api/implementation/app';

function invitationAccept(email, password, passwordConfirmation, token) {
  return post('invitation/accept', {
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
    token: token,
  });
}

function verify(token, email, password, passwordConfirmation) {
  return post('registration/verify', {
    token: token,
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
  });
}

function register(email, name) {
  return post('registration', {
    email,
    name,
  });
}

export {
  invitationAccept,
  register,
  verify,
};
